import { CSS_VARIABLES } from "../../constants/CSS.const";

export const disablePullToRefresh = () => {
  const htmlELe = document.querySelector("html");
  htmlELe?.style.setProperty("overscroll-behavior-y", "none");
  const bodyELe = document.querySelector("body");
  bodyELe?.style.setProperty("overscroll-behavior-y", "none");
};

export const enablePullToRefresh = () => {
  const htmlELe = document.querySelector("html");
  htmlELe?.style.removeProperty("overscroll-behavior-y");
  const bodyELe = document.querySelector("body");
  bodyELe?.style.removeProperty("overscroll-behavior-y");
};

export const setVh = () => {
  const vh = (window?.visualViewport?.height || window.innerHeight) * 0.01;
  document.documentElement.style.setProperty(CSS_VARIABLES.VIEW_HEIGHT, `${vh}px`);
};
