import React, { useEffect } from "react";
import { RouteProps, Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuthenticationSelector } from "../store/slices/AuthenticationSlice";
import { AppConstants, USER_CATEGORY } from "../constants/AppConstants";
import { SessionStorage } from "../services";

/**
 *
 * @param props route props and user category - user category is either empty string for both or specify USER_CATEGORY
 * @returns
 */
export const PrivateRoute = (props: RouteProps & { routeFor: "" | USER_CATEGORY }): React.ReactElement | null => {
  const { path, routeFor } = props;
  const { isLoggedIn, userCategory } = useAuthenticationSelector();
  const hasRouteAccess = props?.routeFor ? userCategory === props.routeFor : true;
  const location = useLocation();
  const { STORAGE_KEYS } = AppConstants;

  useEffect(() => {
    // Store redirect path in session storage if user is not logged in
    if (isLoggedIn) {
      SessionStorage.remove(STORAGE_KEYS.redirectTo);
    } else {
      SessionStorage.set(STORAGE_KEYS.redirectTo, `${location.pathname}${location.search}`);
    }
  }, [isLoggedIn]);

  // Redirect to user category login page if user category is specified and not matching
  return isLoggedIn ? (
    hasRouteAccess ? (
      <Outlet />
    ) : (
      <Navigate state={{ from: path }} replace to={userCategory === USER_CATEGORY.CLIENT ? "/dashboard" : "/pos"} />
    )
  ) : (
    <Navigate state={{ from: location }} replace to={userCategory ? `/${userCategory}/login` : "/"} />
  );
};
