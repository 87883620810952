import { RootState } from '../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiConfig from 'constants/ApiConfigs.const';
import { IAuthenticateUser, IAuthenticationState, IForgotPassword, ILoginClientVendor, IRegisterClient, IResetPassword } from 'interfaces/Authentication.interface';
import { useSelector } from 'react-redux';
import { getActionNameForLoader, getApiRespObj } from '../../shared/helpers/store.helper';
import { setErrorToast, setSuccessToast } from './ToastSlice';
import { setAuthHeader } from '../../shared/helpers/httpService.helper';
import { HttpService } from '../../services';
import { PURGE } from 'redux-persist';
import { USER_CATEGORY } from '../../constants/AppConstants';

const initialState: IAuthenticationState = {
  isLoggedIn: false,
  userCategory: null,
  registrationData: {
    otpError: false,
    validateOtp: false,
    user: {
      _id: "",
      email: "",
    }
  },
  loginData: {
    clientId: "",
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    companyName: "",
    vendorId: ""
  }
};

/******* CLIENT *******/
export const registerClient = createAsyncThunk(getActionNameForLoader("authentication/registerClient"), async (signUpData: IRegisterClient, { rejectWithValue, dispatch }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.register_client, signUpData);
    return response.data.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

export const authenticateClientUser = createAsyncThunk(getActionNameForLoader("authentication/authenticateClientUser"), async (loginData: IAuthenticateUser, { dispatch, rejectWithValue }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.authenticate_user, loginData);
    return response.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

export const resendAuthenticationCode = createAsyncThunk(getActionNameForLoader("authentication/resendAuthenticationCode"), async (email: string, { dispatch, rejectWithValue }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.resend_otp, { email });
    dispatch(setSuccessToast({ message: response?.data?.message }));
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

export const loginClient = createAsyncThunk(getActionNameForLoader("authentication/loginClient"), async (loginData: ILoginClientVendor, { rejectWithValue, dispatch }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.login_client, loginData);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});


/**** vendor side ****/
// export const registerVendor = createAsyncThunk(getActionNameForLoader("authentication/registerVendor"), async (signUpData: IRegisterClient, { rejectWithValue, dispatch }) => {
//   try {
//     const response = await HttpService.post(ApiConfig.endpoints.register_vendor, signUpData);
//     return getApiRespObj(response.data.data);
//   } catch (error: any) {
//     dispatch(setErrorToast({ message: error?.response?.data?.message }));
//     return rejectWithValue(getApiRespObj(error?.response?.data));
//   }
// });

export const loginVendor = createAsyncThunk(getActionNameForLoader("authentication/loginVendor"), async (loginData: ILoginClientVendor, { dispatch, rejectWithValue }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.login_vendor, loginData);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

/***** common ******/
export const resetPassword = createAsyncThunk(getActionNameForLoader("authentication/resetPassword"), async (resetPasswordData: IResetPassword, { rejectWithValue, dispatch }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.reset_password, resetPasswordData);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

export const getPasswordResetLink = createAsyncThunk(getActionNameForLoader("authentication/getPasswordResetLink"), async (forgotPasswordData: IForgotPassword, { rejectWithValue, dispatch }) => {
  try {
    const response = await HttpService.post(ApiConfig.endpoints.forgot_password, forgotPasswordData);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.loginData = action.payload;
    },
    resetRegistrationData: (state) => {
      state.registrationData = initialState.registrationData;
    },
    setUserCategory: (state, action) => {
      state.userCategory = action.payload;
    },
    handleSessionExpired: (state) => {
      state.isLoggedIn = false;
      state.loginData = initialState.loginData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);

    builder.addCase(registerClient.fulfilled, (state, action) => {
      state.registrationData.user = action.payload ?? initialState.registrationData;
      state.registrationData.otpError = false;
      state.registrationData.validateOtp = true;
    });

    builder.addCase(authenticateClientUser.fulfilled, (state, action) => {
      state.registrationData.otpError = false;
      state.registrationData.validateOtp = false;
      state.loginData = action.payload.data ?? initialState.loginData;
      state.isLoggedIn = true;
      setAuthHeader(action.payload.data.token);
    });
    builder.addCase(authenticateClientUser.rejected, (state) => {
      state.registrationData.otpError = true;
      state.registrationData.validateOtp = true;
    });

    builder.addCase(loginClient.fulfilled, (state, action) => {
      if (action.payload?.data?.token) {
        state.loginData = action.payload.data ?? initialState.loginData;
        state.isLoggedIn = true;
        state.userCategory = USER_CATEGORY.CLIENT;
      } else {
        state.registrationData.validateOtp = true;
        state.registrationData.user = action.payload.data;
      }
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.registrationData.validateOtp = true;
    });

    builder.addCase(loginVendor.fulfilled, (state, action) => {
      if (action.payload?.data?.token) {
        state.loginData = action.payload.data ?? initialState.loginData;
        state.isLoggedIn = true;
        state.userCategory = USER_CATEGORY.VENDOR;
      }
    });
  }
});

export const { setLoginData, resetRegistrationData, setUserCategory, handleSessionExpired } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;

export const useAuthenticationSelector = () => useSelector((state: RootState) => state.authentication);

export const useCheckUserCategory = (user: USER_CATEGORY) =>
  useSelector((state: RootState) => state.authentication.userCategory === user);