// src/services/httpService.js
import axios from "axios";
import { AppDispatch, RootState } from "../store/store";
import { Persistor } from "redux-persist";
import { onSessionExpiration } from "../shared/helpers/httpService.helper";

const httpService = axios.create({
  baseURL: process.env.REACT_APP_MMT_API_URL, // Set your API base URL here
  timeout: 60000, // Set your timeout if needed
});

// Add an interceptor to set the Authorization header for every request
export const setupAxiosInterceptors = (getState: () => RootState, dispatch: AppDispatch, persistor: Persistor) => {
  httpService.interceptors.request.use(
    (config) => {
      const {
        loginData: { token },
      } = getState().authentication;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  httpService.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        onSessionExpiration(getState().authentication.loginData, dispatch, persistor);
      }
      return Promise.reject(error);
    }
  );
};

export default httpService;
