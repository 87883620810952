// src/store.js
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthenticationSlice, WorkspaceSlice, LoaderSlice, ToastSlice, ProjectSlice, POSlice } from './slices';
import DataImportSlice from './slices/DataImportSlice';
import { setupAxiosInterceptors } from '../services/HttpService';

const persistConfig = {
  version: 1,
  key: 'root',
  storage,
  // stateConciler: autoMergeLevel2 // read more about it here: https://www.npmjs.com/package/redux-persist#state-reconciler

};
// add all slices here
const rootReducer = combineReducers({
  // authethication: authenticationSlice.reducer,
  authentication: AuthenticationSlice,
  workspace: WorkspaceSlice,
  loader: LoaderSlice,
  toast: ToastSlice,
  project: ProjectSlice,
  po: POSlice,
  dataImport: DataImportSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;

export const persistor = persistStore(store);

// Setup Axios interceptors by passing getState and dispatch dynamically to avoid circular dependencies
setupAxiosInterceptors(store.getState, store.dispatch as AppDispatch, persistor);