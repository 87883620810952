import { AppConstants } from "../../constants/AppConstants";
import { ILoginResponse } from "../../interfaces/Authentication.interface";
import { SessionStorage } from "../../services";
import htttpService from "../../services/HttpService";
import { handleSessionExpired } from "../../store/slices/AuthenticationSlice";

export const setAuthHeader = (token: string) => {
  if (token) {
    token = `Bearer ${token}`;
    htttpService.defaults.headers.common.Authorization = token;
  } else {
    delete htttpService.defaults.headers.common.Authorization;
  }
};

export const onSessionExpiration = (loginData: ILoginResponse, dispatch: any, persistor: any) => {
  const { STORAGE_KEYS } = AppConstants;

  loginData.email && SessionStorage.set(STORAGE_KEYS.prevLoggedInUser, { email: loginData.email });
  dispatch(handleSessionExpired());
};
