import { Action, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { PURGE } from "redux-persist";

const initialState = {
  isLoading: false,
  loadingCount: 0,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    resetLoader: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    // upon logout
    builder.addCase(PURGE, () => initialState);

    builder
      .addMatcher(
        (action: Action) => action.type.includes("/pending") && action.type.includes(":load"),
        (state) => {
          state.isLoading = true;
          ++state.loadingCount;
        }
      )
      .addMatcher(
        (action: Action) => action.type.includes("/fulfilled") && action.type.includes(":load"),
        (state) => {
          --state.loadingCount;
          if (state.loadingCount <= 0) {
            state.isLoading = false;
            state.loadingCount = 0;
          }
        }
      )
      .addMatcher(
        (action: Action) => action.type.includes("/rejected") && action.type.includes(":load"),
        (state) => {
          --state.loadingCount;
          if (state.loadingCount <= 0) {
            state.isLoading = false;
            state.loadingCount = 0;
          }
        }
      );
  },
});

export const { startLoading, stopLoading, resetLoader } = loaderSlice.actions;

export default loaderSlice.reducer;

export const useLoaderSelector = () => useSelector((state: RootState) => state.loader);
